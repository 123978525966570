@import "bootstrap/scss/functions";
@import "src/variables";
@import "bootstrap/scss/mixins/breakpoints";

// Respond above.
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin mobile-landscape {
  // Write the media query.
  @media screen and (min-device-aspect-ratio: 1/1) and (orientation: landscape) and (max-device-width: 767px) {
    @content;
  }
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}
