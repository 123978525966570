@use '@angular/material' as mat;

@include mat.core();

@import "variables";
@import "bootstrap/scss/bootstrap";
@import "functions";
@import "collapse";


html, body {
  height: 100%;
  width: 100%;
}

.c-pointer {
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;

  &.x1-5 {
    width: 36px;
    height: 36px;
  }

  &.x2 {
    width: 48px;
    height: 48px;
  }
}

.sanakey-color {
  &.fill {
    fill: $SanakeyBlue;
  }

  &.color {
    color: $SanakeyBlue;
  }

  &.bg-color {
    background-color: $SanakeyBlue;
  }

  &.border-color {
    border-color: $SanakeyBlue;
  }
}

.sanakey-container {
  @extend .p-2;
  border-radius: 5px;
  background-color: white;

  strong {
    display: block;
    width: 100%;
  }

  small {
    display: block;
    width: 70%;
  }
}

.sanakey-kachel {
  @extend .sanakey-container;
  cursor: pointer;
  height: 3rem * $line-height-base;

  strong {
    display: block;
    width: 100%;
  }

  small {
    display: block;
    width: 70%;
  }

  &.hoverable:hover {
    background-color: darken(white, 10);
  }
}

.btn-primary {
  background-color: $SanakeyBlue;
  border-color: $SanakeyBlue;

  &, &:hover, &:active {
    color: $white !important;
  }
}

.validity-indicator-bg {
  transition: background-color 400ms ease;

  &.has-error {
    background-color: $danger;
    color: $light;
  }

  &.is-valid {
    background-color: $green;
    color: $light;
  }
}

.shrink-top-animated {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition-property: max-height, opacity;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;

  &.delayed {
    transition-delay: 200ms;
  }

  &.out {
    max-height: 100%;
    opacity: 1;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

@include respond-above(md) {
  .xxl-modal {
    max-width: 100%;

    .modal-dialog {
      max-width: 90vw;
    }
  }
}

@include respond-above(lg) {
  .xxl-modal {
    max-width: 100%;

    .modal-dialog {
      max-width: 80vw;
    }
  }
}

button:focus, input[type="button"]:focus,
.btn:focus, .btn.focus,
.nav-btn:focus, .nav-btn.focus,
.navbar-brand:focus {
  outline: none;
  box-shadow: none;
}

.toast {
  background-color: $white;

  &.success {
    .toast-header {
      border-bottom-color: $green;
    }
  }

  &.danger {
    .toast-header {
      border-bottom-color: $red;
    }
  }

  &.warning {
    .toast-header {
      border-bottom-color: $orange;
    }
  }

  &.interactive {
    .toast-header {
      border-bottom-color: $orange;
    }
  }
}

.icons {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  fa-icon {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
  }

  .icon-default {
    transition: opacity .3s, transform .3s;
  }

  .icon-second {
    transition: opacity .3s, transform .3s;
    transform: rotate(-180deg) scale(.5);
    opacity: 0;
  }
}

.icons.show-second, .show-second .icons {
  .icon-second {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  .icon-default {
    transform: rotate(180deg) scale(.5);
    opacity: 0;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.underlined {
  text-decoration: underline;
}

.decoration-primary {
  text-decoration-color: theme-color("primary");
}

.pagination {
  margin-bottom: 0;
}

.overflow-x-auto {
  overflow-x: auto;
}

.shadow-inset {
  box-shadow: 0 0 0.5rem inset rgba($black, .15);
}

ngb-typeahead-window.dropdown-menu.show {
  //max-height: 100px; /* 32px x X */
  max-height: 300px;
  overflow-y: auto;
  width: calc(100% - 10px);
}

ngb-progressbar {
  .progress-bar {
    height: 100%;
  }
}


.click-cursor {
  cursor: pointer;
}

/* backwards compatibility with bootstrap 4 */
.form-group {
  margin-bottom: 1rem;
}

.card-body {
  padding: 1.25rem;
}

.my-hover {
  &:hover {
    color: $table-hover-color !important;
    background-color: $table-hover-bg !important;
  }
}

.my-icon-hover {
  &:hover {
    color: $table-hover-color !important;
  }
}

label {
  display: inline-block;
  margin-bottom: $label-margin-bottom;
}

.form-group.col,.form-group[class*=col-] {
  padding-right: 5px;
  padding-left: 5px
}

hr {
  margin: 0;
}

.table > :not(caption) > * > * {
  box-shadow: none;
}

.btn-outline-primary:hover {
  color: $white;
}

.btn-dummy-data-container {
  position: fixed;
  bottom: 8em;
  height: 0;
  width: auto;
  overflow: visible;
  display: inline-block;
}

.btn-dummy-data {
  margin-left: 1em;
}

.ng-toggle-switch-core {
  display: inline-block !important;
}

.index-containter {
  position: static;
  height: 90vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.logo-container {
  position: relative;
  align-self: center;
  width: 10rem;
  height: 10rem;
}

.animated_logo {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  opacity: 0;
  animation: pulseEffect 1s ease-out infinite;
}

@keyframes pulseEffect {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.static-logo {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
